<template>
  <el-dialog
    :show-close="false"
    :visible="dialogVisible"
    @open="getEnterprise"
    @close="closeDialog"
  >
    <div slot="title" class="left_top">
      <div class="left_icon"><span></span> <span>编辑企业</span></div>
    </div>
    <el-form
      label-position="top"
      width="900px"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="80px"
    >
      <el-divider content-position="left">基本信息</el-divider>
      <el-form-item label="企业名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>

      <el-form-item label="统一信用编码" prop="idCardNumber">
        <el-input v-model="ruleForm.idCardNumber"></el-input>
      </el-form-item>
      <el-form-item label="住所" prop="address">
        <el-input v-model="ruleForm.address"></el-input>
      </el-form-item>

      <el-form-item label="营业执照照片" prop="idCardImage">
        <Upload v-model="ruleForm.idCardImage" path="/enterprise.upload.json">
          上传
        </Upload>
      </el-form-item>
      <el-divider content-position="left">法人信息</el-divider>
      <el-form-item label="法人姓名" prop="juridicalPersonName">
        <el-input v-model="ruleForm.juridicalPersonName"></el-input>
      </el-form-item>

      <el-form-item label="法人身份证号" prop="juridicalPersonId">
        <el-input v-model="ruleForm.juridicalPersonId"></el-input>
      </el-form-item>

      <el-form-item label="法人身份证正面" prop="juridicalPersonIdFont">
        <Upload
          v-model="ruleForm.juridicalPersonIdFont"
          path="/enterprise.upload.json"
        >
          上传
        </Upload>
      </el-form-item>

      <el-form-item label="法人身份证反面" prop="juridicalPersonIdBack">
        <Upload
          v-model="ruleForm.juridicalPersonIdBack"
          path="/enterprise.upload.json"
        >
          上传
        </Upload>
      </el-form-item>

      <el-divider content-position="left">开户信息</el-divider>
      <el-form-item label="银行" prop="bankName">
        <el-input v-model="ruleForm.bankName"></el-input>
      </el-form-item>

      <el-form-item label="银行卡号" prop="bankCode">
        <el-input v-model="ruleForm.bankCode"></el-input>
      </el-form-item>

      <el-form-item label="开户地" prop="bankArea">
        <District v-model="ruleForm.bankArea" :level="level"></District>
      </el-form-item>

      <el-form-item label="支行" prop="bankBranch">
        <el-input v-model="ruleForm.bankBranch"></el-input>
      </el-form-item>

      <el-form-item label="支行联行号" prop="bankBranchCode">
        <el-input v-model="ruleForm.bankBranchCode"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')"
        >确 定</el-button
      >
      <el-button @click="closeDialog()">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import moment from 'moment';
import { api } from '/src/api/base';
const request = api('/enterprise');
import * as _ from 'lodash';
import District from '/src/components/district';
export default {
  components: {
    District,
    Upload: () => import('/src/components/upload.vue')
  },
  data() {
    return {
      ruleForm: {
        name: '',
        idCardNumber: '',
        address: '',
        idCardImage: '',
        juridicalPersonName: '',
        juridicalPersonId: '',
        juridicalPersonIdFont: '',
        juridicalPersonIdBack: '',
        bankName: '',
        bankCode: '',
        bankArea: '',
        bankBranch: '',
        bankBranchCode: ''
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        idCardNumber: [
          { required: true, message: '请输入统一信用编码', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入统一信用编码', trigger: 'blur' }
        ],
        idCardImage: [
          { required: true, message: '请上传营业执照照片', trigger: 'blur' }
        ],

        juridicalPersonName: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' }
        ],
        juridicalPersonId: [
          { required: true, message: '请输入法人身份证号', trigger: 'blur' }
        ],
        juridicalPersonIdFont: [
          {
            required: true,
            message: '请上传法人身份证正面照片',
            trigger: 'blur'
          }
        ],
        juridicalPersonIdBack: [
          {
            required: true,
            message: '请上传法人身份证反面照片',
            trigger: 'blur'
          }
        ],

        bankName: [{ required: true, message: '请输入银行', trigger: 'blur' }],

        bankCode: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' }
        ],

        bankArea: [
          { required: true, message: '请输入开户地', trigger: 'blur' }
        ],

        bankBranch: [
          { required: true, message: '请输入支行', trigger: 'blur' }
        ],

        bankBranchCode: [
          { required: true, message: '请输入支行联行号', trigger: 'blur' }
        ]
      },
      level: 'city'
    };
  },
  props: {
    dialogVisible: Boolean,
    dialogData: String
  },
  methods: {
    getEnterprise() {
      request('enterprise.describe.json')({ code: this.dialogData }).then(
        res => {
          Object.keys(this.ruleForm).map(it => {
            this.ruleForm[it] = res[it];
          });
          console.log(this.ruleForm.bankArea);
        }
      );
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = {};
          Object.keys(this.ruleForm).forEach(key => {
            const it = this.ruleForm[key];
            if (_.isDate(it)) {
              params[key] = moment(it).format('YYYY-MM-DD HH:mm');
              return;
            }
            if (_.isArray(it)) {
              params[key] = it.join(',');
              return;
            }
            params[key] = it;
          });
          request('enterprise.edit.json')({ ...params, code: this.code }).then(
            res => {
              console.log(res);
              this.closeDialog();
            }
          );
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    closeDialog() {
      this.$emit('update:dialogVisible', false);
      this.$emit('close', this.dialogVisible);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
